import {makeStyles} from '@material-ui/core/styles'
import Menu, {MenuProps} from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AppTypographyLink from 'src/components/elements/AppTypographyLink'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'
import {sections} from './data'
import {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'next-i18next'
import AppTypography from 'src/components/elements/typography/AppTypography'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({breakpoints, spacing, shadows, palette}) => ({
    paper: {
      background: 'white', //isTop ? 'black' : 'white',
      marginTop: spacing(2),
      maxHeight: 'fit-content',
      '& .MuiPopover-paper': {
        boxShadow: shadows[2],
      },
      [breakpoints.down('md')]: {
        maxHeight: 'calc(100vh - 16px) !important',
        marginTop: spacing(1),
      },
    },
    closeIcon: {
      display: 'none',
      position: 'absolute',
      right: spacing(1),
      top: spacing(1),
      fontSize: 16,
      [breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  }),
  {index: 1},
)

//todo convert this to not using menu
export default function DiscoverPlacesMenu(
  props: {onClose: () => void} & MenuProps,
) {
  const {onClose} = props

  const classes = useStyles()

  const [showClientSideComponents, setShowClientSideComponents] =
    useState<boolean>(false)

  useEffect(() => {
    setShowClientSideComponents(true)
  }, [])

  return (
    <Fragment>
      <Content isSeo />
      {showClientSideComponents && (
        <Menu
          classes={{
            paper: classes.paper,
            list: 'p-0',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={{onMouseLeave: onClose}}
          getContentAnchorEl={null}
          {...props}
        >
          <div className="p-4 w-full h-full">
            <Content />
          </div>
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Menu>
      )}
    </Fragment>
  )
}

function Content(props: {isSeo?: boolean}) {
  const {isSeo} = props

  const {t} = useTranslation('header')
  const currentLocale = useCurrentLocale()

  const hiddenStyle = isSeo ? {display: 'none'} : {}

  return (
    <div className="w-full m-0 max-h-full" style={hiddenStyle}>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {sections.map(({title, links}, sectionIdx) => (
          <ul key={sectionIdx}>
            <AppTypography
              variant="caption"
              className="text-primary-main uppercase mb-4"
              component="span"
            >
              {t(title)}
            </AppTypography>
            {links.map(({label, path, label_en, path_en}) => {
              return (
                <li key={label} className="list-none">
                  <AppTypographyLink
                    href={currentLocale === LOCALE_EN ? path_en : path}
                    style={{marginBottom: 8}}
                    variant="body2"
                    className="text-primary-main"
                  >
                    {currentLocale === LOCALE_EN ? label_en : label}
                  </AppTypographyLink>
                </li>
              )
            })}
          </ul>
        ))}
      </div>
    </div>
  )
}
