import {useRouter} from 'next/router'
import {useSelector} from 'react-redux'
import {useTranslation} from 'next-i18next'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import AppTypography from 'src/components/elements/typography/AppTypography'
import AppIcon from 'src/components/elements/icons/AppIcon'
import {
  bookingBadgeSelector,
  messageBadgeSelector,
  propertyBadgeSelector,
} from 'src/store/selectors/badgeSelector'
import {
  ACCESS_BOOKING,
  ACCESS_INBOX,
  ACCESS_OVERVIEW,
  ACCESS_PROPERTY,
} from 'src/constants/collaboratorAccess'
import {useHasAccess} from 'src/hooks/permissionAndAccess'
import {useIsAdmin, useIsGuestMenu} from 'src/hooks/user'
import {
  ADMIN_GUEST_BOOKINGS_PAGE,
  ADMIN_GUEST_MESSAGES_PAGE,
  ADMIN_INBOX_PAGE,
  ADMIN_PAGE,
  ADMIN_PROPERTIES_PAGE,
  ADMIN_RESERVATIONS_PAGE,
} from 'src/constants/route'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette}) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing(2),
  },
  badge: {
    width: spacing(3),
    height: spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: spacing(3),
    backgroundColor: palette.common.white,
  },
  badgeForPopup: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
  },
  label: {
    color: `${palette.common.white} !important`,
  },
}))

export default function MenuItem(props: {
  label: string
  link?: string
  icon: string
  isSideBar?: boolean
  onClose?: () => void
  hidden?: boolean
}) {
  const {label, link, icon, isSideBar, onClose, hidden = false} = props

  const classes = useStyles()
  const {t} = useTranslation('header')
  const router = useRouter()

  const menuItemClassName = isSideBar ? classes.label : ''
  const badge = useBadge(link)
  const isVisible = useIsVisible(link)

  const onClick = () => {
    if (onClose) {
      onClose()
    }

    if (!link) {
      return
    }

    router.push(link)
  }

  if (hidden || !isVisible) {
    return null
  }

  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.content}>
        <div
          className="flex flex-col justify-center items-center"
          style={{width: '16px', height: '16px'}}
        >
          <AppIcon name={icon} />
        </div>
        <AppTypography
          variant="action"
          neutralColor={800}
          className={menuItemClassName}
        >
          {t(label)}
        </AppTypography>
      </div>
      <CustomBadge badge={badge} isSideBar={isSideBar} />
    </div>
  )
}

function CustomBadge(props: {badge?: string; isSideBar?: boolean}) {
  const {badge, isSideBar} = props

  const classes = useStyles()

  const className = isSideBar
    ? classes.badge
    : clsx(classes.badge, classes.badgeForPopup)

  if (!badge) {
    return null
  }

  return (
    <div className={className}>
      <AppTypography variant="subHeading">{props.badge}</AppTypography>
    </div>
  )
}

function useBadge(path: string = '') {
  const bookingBadgeInfo = useSelector(bookingBadgeSelector)
  const badgeInfo = useSelector(messageBadgeSelector)
  const isAdmin = useIsAdmin()
  const {citq_badge: citqBadgeCount} = useSelector(propertyBadgeSelector)
  const {host_badge: hostBadge, guest_badge: guestBadge} = badgeInfo
  const {host_badge: hostBookingBadge, guest_badge: guestBookingBadge} =
    bookingBadgeInfo

  if (path === ADMIN_GUEST_MESSAGES_PAGE) {
    return guestBadge
  }

  if (path === ADMIN_INBOX_PAGE) {
    return hostBadge
  }

  if (path === ADMIN_GUEST_BOOKINGS_PAGE) {
    return guestBookingBadge
  }

  if (path === ADMIN_RESERVATIONS_PAGE) {
    return hostBookingBadge
  }

  if (isAdmin && path === ADMIN_PROPERTIES_PAGE) {
    return citqBadgeCount
  }

  return null
}

function useIsVisible(path: string = '') {
  const isGuestMenu = useIsGuestMenu()
  const hasAccessProperty = useHasAccess(ACCESS_PROPERTY)
  const hasAccessOverview = useHasAccess(ACCESS_OVERVIEW)
  const hasAccessInbox = useHasAccess(ACCESS_INBOX)
  const hasAccessBooking = useHasAccess(ACCESS_BOOKING)

  if (isGuestMenu) {
    return true
  }

  if (path === ADMIN_PAGE && !hasAccessOverview) {
    return false
  }

  if (path === ADMIN_INBOX_PAGE && !hasAccessInbox) {
    return false
  }

  if (path === ADMIN_RESERVATIONS_PAGE && !hasAccessBooking) {
    return false
  }

  if (path === ADMIN_PROPERTIES_PAGE && !hasAccessProperty) {
    return false
  }

  return true
}
