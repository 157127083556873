import {makeStyles} from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import DateRangePicker from 'src/components/elements/DateRangePicker'
import {DatesStatusMap} from 'src/types/form'
import {SelectDateProps} from 'src/types/utils'
import {useEffect, useState} from 'react'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({breakpoints, spacing, shadows}) => ({
  dateMenu: {
    '& .MuiPopover-paper': {
      maxWidth: 624,
      marginTop: spacing(2),
      right: '0 !important',
      borderRadius: spacing(2),
      boxShadow: shadows[2],
      [breakpoints.down('md')]: {
        margin: '10px !important',
        left: '0px !important',
      },
    },
  },
}))

export default function DatePickerMenu(props: {
  anchorEl: HTMLElement | null
  disableFlexibleDates?: boolean
  disableButtons?: boolean
  showPrice?: boolean
  datesStatusMap?: DatesStatusMap
  selectedDates: SelectDateProps
  setSelectedDates: (value: SelectDateProps) => void
  halfDayPicker?: boolean
  handleClose: () => void
  visibleMonths: string[]
  nextMonth: () => void
  previousMonth: () => void
}) {
  const {
    showPrice,
    anchorEl,
    selectedDates,
    disableFlexibleDates,
    disableButtons,
    datesStatusMap,
    visibleMonths,
    previousMonth,
    nextMonth,
    setSelectedDates,
    handleClose,
    halfDayPicker,
  } = props

  const classes = useStyles()

  const [showClientSideComponents, setShowClientSideComponents] =
    useState<boolean>(false)

  useEffect(() => {
    setShowClientSideComponents(true)
  }, [])

  if (!showClientSideComponents) {
    return null
  }

  const isOpen = Boolean(anchorEl)

  return (
    <Menu
      id="date-menu"
      className={classes.dateMenu}
      anchorEl={anchorEl}
      keepMounted
      open={isOpen}
      onClose={handleClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <DateRangePicker
        visibleMonths={visibleMonths}
        previousMonth={previousMonth}
        nextMonth={nextMonth}
        datesStatusMap={datesStatusMap}
        selectedDates={selectedDates}
        opened={isOpen}
        disableButtons={disableButtons}
        disableFlexibleDates={disableFlexibleDates}
        changeOnSelect={disableButtons}
        setSelectedDates={setSelectedDates}
        onClose={handleClose}
        halfDayPicker={halfDayPicker}
        showPrice={showPrice}
        canSelectSameDay={false}
      />
    </Menu>
  )
}
